import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Script } from 'gatsby';
import { castArray } from 'lodash';
import { Helmet } from 'react-helmet';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from 'components/layout';
import JournalPostHeader from 'components/journal-post-header';
import JournalPostNav from 'components/journal-post-nav';
import PageContent from 'components/page-content';

const JournalPostTemplate = ({ data, pageContext, location }) => {
  const {
    title,
    type,
    excerpt,
    date,
    thumbnailImage,
    content,
  } = data.contentfulJournalPost;
  const { previous, next } = pageContext;

  const pageTitleFull = title
    ? `${title} | The Neighborhood Design Center`
    : 'The Neighborhood Design Center';
  const fullDescription =
    (excerpt && excerpt.excerpt.substring(0, 155)) ||
    (content &&
      content.raw &&
      documentToPlainTextString(content.raw).substring(0, 155)) ||
    null;
  const imageUrl =
    (thumbnailImage &&
      `${thumbnailImage?.gatsbyImageData?.images?.fallback?.src}`) ||
    null;

  return (
    <Layout location={location}>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div className="bg-white boundary">
        <JournalPostHeader
          title={title}
          type={castArray(type)}
          date={date}
          pageColor="blue"
          previous={previous}
          next={next}
        />
        <PageContent margin="my-0 sm:my-0" content={content} pageColor="blue" />
        <JournalPostNav pageColor="blue" previous={previous} next={next} />
        
        <Script id="mc-embedded-global-id">{`
          const subscribeButton = document.getElementById('mc-embedded-subscribe');
          const emailInput = document.getElementById('mce-EMAIL');
          const checkboxes = document.querySelectorAll('input[type="checkbox"]');
          const checkboxGroup = document.getElementById('mc-checkbox-group');

          if (!subscribeButton || !emailInput || checkboxes.length === 0 || !checkboxGroup) {
            throw new Error('Uno o más elementos necesarios no están disponibles.');
          }

          // Helper function to update button state and styles
          function updateButtonState() {
            const emailFilled = emailInput.value.trim() !== '';
            const checkboxChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
            const isFormValid = emailFilled && checkboxChecked;

            subscribeButton.disabled = !isFormValid;

            if (isFormValid) {
              subscribeButton.classList.remove('disabled');
            } else {
              subscribeButton.classList.add('disabled');
            }
          }

          // Initial button state and styles
          subscribeButton.classList.add('disabled');

          // Attach event listeners
          emailInput.addEventListener('input', updateButtonState);
          checkboxes.forEach(checkbox => checkbox.addEventListener('change', updateButtonState));

          updateButtonState();
        `}</Script>

      </div>
    </Layout>
  );
};

JournalPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default JournalPostTemplate;

export const query = graphql`
  query JournalPostBySlug($slug: String!) {
    contentfulJournalPost(slug: { eq: $slug }) {
      id
      title
      published
      type
      date
      thumbnailImage {
        gatsbyImageData(width: 1024, height: 512)
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAList {
            contentful_id
            columnCount
            includeIcon
            list {
              raw
            }
            textColor
          }
          ... on ContentfulAButton {
            contentful_id
            text
            link
            asset {
              id
              file {
                url
              }
            }
            opensNewTab
          }
          ... on ContentfulMailchimpEmbed {
            contentful_id
            label
            code {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulASpacer {
            contentful_id
            height
            includeDivider
          }
          ... on ContentfulUtilityBlock {
            contentful_id
            text
            type
          }
          ... on ContentfulAnImageText {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            imageSide
            imageType
            textDescription: text {
              raw
            }
          }
          ... on ContentfulAPullquote {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            quote {
              id
              quote
            }
            attribution
          }
          ... on ContentfulAnImageGroupSlideshowCollage {
            contentful_id
            images {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            displayAs
            blockWidth
            showCaption
          }
          ... on ContentfulSubscribeAndSocial {
            contentful_id
            facebookLink
            instagramLink
            twitterLink
            linkedinLink
            youtubeLink
            newsletterTitle
            socialLinksTitle
          }
          ... on ContentfulFeaturedVolunteers {
            contentful_id
            featuredVoluteersHeading
          }
          ... on ContentfulAList {
            contentful_id
            columnCount
            includeIcon
            list {
              raw
            }
            textColor
          }
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData(width: 1500, placeholder: BLURRED)
          }
          ... on ContentfulAnImageOrVideo {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            showCaption
            videoUrl
            blockWidth
          }
        }
      }
    }
  }
`;
